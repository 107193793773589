<template lang="">
  <v-row>
    <v-col cols="12" class="mt-2">
      <h1 class="display-1 font-weight-medium">
        Standard Operating Procedures
      </h1>
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="standardOperatingProcedures"
        :search="search"
        sort-by="calories"
        class="elevation-1"
      >
        <template #item.document="{ item }">
          <a target="_blank" :href="item.document">
            <v-icon :style="{ color: `#1976D2` }">mdi mdi-file-download</v-icon>
          </a>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row class="mt-2">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    <v-btn
                      v-if="auth"
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Add New SOP
                    </v-btn>
                  </div>
                </template>

                <v-card v-if="dialog">
                  <v-form ref="form" @submit.prevent="save">
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-text-field
                          v-model="editedItem.title"
                          label="Title"
                          :rules="[(v) => !!v || 'Field Required']"
                          :loading="loading"
                          :disabled="loading"
                        ></v-text-field>

                        <!-- <v-file-input
                    v-model="editedItem.document"
                    label="Upload a document"
                    :rules="[(v) => !!v || 'Field Required']"
                    accept=".pdf,.doc"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    :loading="loading"
                    :disabled="loading"
                  >
                  
                  </v-file-input> -->
                        <v-textarea
                          v-model="editedItem.notes"
                          :rules="[(v) => !!v || 'Field Required']"
                          label="Notes"
                          :loading="loading"
                          :disabled="loading"
                        ></v-textarea>
                        <v-row class="mt-2">
                          <v-col cols="12" md="6">
                            <span>
                              <v-btn
                                :style="{ width: `100%` }"
                                class="white--text"
                                color="teal lighten-2"
                                :loading="loading"
                                :disabled="loading"
                                depressed
                                v-if="docButtonDisable"
                                @click="clear"
                                ><p class="mb-0 overline text-capitalize">
                                  {{ docName.substring(0, 16) }}...
                                </p>
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </span>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-btn
                              color="primary"
                              :style="{ width: `100%` }"
                              :disabled="docButtonDisable || loading"
                              @click="$refs.fileInput.click()"
                              :loading="loading"
                            >
                              Upload a document
                              <input
                                ref="fileInput"
                                type="file"
                                accept=".pdf,.doc"
                                style="display:none"
                                @change="onFileSelected"
                              />
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :loading="loading"
                        :disabled="loading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :loading="loading"
                        :disabled="loading || !docName"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                    :loading="loading"
                    :disabled="loading"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    :loading="loading"
                    :disabled="loading"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-if="auth" v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)" v-if="auth">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)" v-if="auth">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { mainApp, portalsApp } from "../firebase";
const standardOperatingProcedures = portalsApp
  .firestore()
  .collection("standardOperatingProcedures");
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind(
      "standardOperatingProcedures",
      standardOperatingProcedures
    );
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    loading:false,
    docName: "",
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "title",
      },
      {
        text: "Document",
        filterable: false,
        sortable: false,
        value: "document",
      },
      { text: "Notes", filterable: false, sortable: false, value: "notes" },
    ],
    standardOperatingProcedures: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),
  computed: {
    auth() {
      if (this.$store.state.isSuperAdmin) {
        this.headers = [
          {
            text: "Title",
            align: "start",
            sortable: false,
            value: "title",
          },
          {
            text: "Document",
            filterable: false,
            sortable: false,
            value: "document",
          },
          { text: "Notes", filterable: false, sortable: false, value: "notes" },
          {
            text: "Actions",
            filterable: false,
            value: "actions",
            sortable: false,
            align: 'end',
          },
        ];
      }
      return this.$store.state.isSuperAdmin
    },
    formTitle() {
      return this.editedIndex === -1 ? "New SOP" : "Edit SOP";
    },
    loading() {
      return this.$store.state.loading;
    },
    docButtonDisable() {
      return this.docName !== "";
    },
  },

  watch: {
    dialog(val) {
      if (val == false) {
        this.docName = "";
      }
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    clear() {
      this.docName = "";
      this.$refs.fileInput.value = "";
    },
    onFileSelected(event) {
      this.editedItem.document = event.target.files[0];
      this.docName = event.target.files[0].name;
    },
    showName(name) {
      let newName = "string" == typeof name ? "Document" : name;
      return newName;
    },
    editItem(item) {
      this.editedIndex = this.standardOperatingProcedures.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const url = item.document;
      const decodedUrl = decodeURIComponent(url); // Decode any URL-encoded characters in the URL
      const filenameStartIndex = decodedUrl.lastIndexOf("/") + 1; // Get the index of the last forward slash in the URL
      const filenameEndIndex = decodedUrl.lastIndexOf("?"); // Get the index of the question mark in the URL
      const filename = decodedUrl.substring(
        filenameStartIndex,
        filenameEndIndex
      ); // Extract the filename from the URL string
      this.docName = filename;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.standardOperatingProcedures.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loading = true;
      let doc = portalsApp
        .firestore()
        .collection("standardOperatingProcedures")
        .doc(this.standardOperatingProcedures[this.editedIndex].id);
      await doc.delete();
      this.closeDelete();
          this.loading = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      await this.$store.dispatch("setLoading", true);
      this.loading= true
      if (this.editedIndex > -1) {
        if (
          this.standardOperatingProcedures[this.editedIndex].document ==
          this.editedItem.document
        ) {
          let doc = portalsApp
            .firestore()
            .collection("standardOperatingProcedures")
            .doc(this.standardOperatingProcedures[this.editedIndex].id);
          await doc.update(this.editedItem);
          this.loading= false
        } else {
          const pdf = this.editedItem.document;
          const pdfRef = mainApp
            .storage()
            .ref(`standardOperatingProcedures/${pdf.name}`);
          const pdfUploaded = await pdfRef.put(pdf);
          this.editedItem.document = await pdfUploaded.ref.getDownloadURL();
          delete pdf.file;
          let doc = portalsApp
            .firestore()
            .collection("standardOperatingProcedures")
            .doc(this.standardOperatingProcedures[this.editedIndex].id);
          await doc.update(this.editedItem);
          this.loading= false
        }
      } else {
        const pdf = this.editedItem.document;
        const pdfRef = mainApp
          .storage()
          .ref(`standardOperatingProcedures/${pdf.name}`);
        const pdfUploaded = await pdfRef.put(pdf);
        this.editedItem.document = await pdfUploaded.ref.getDownloadURL();
        delete pdf.file;

        await portalsApp
          .firestore()
          .collection("standardOperatingProcedures")
          .add(this.editedItem);
          this.loading= false
      }
      await this.$store.dispatch("setLoading", false);
      this.close();
    },
  },
};
</script>
